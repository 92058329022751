import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logWebActivity } from 'src/common/actions/logging';
import { DOWNLOAD_DESKTOP_BUTTON } from 'src/common/constants/localizations/desktop';
import connectWithExperiments from '../../decorators/connectWithExperiments';

import isMacDesktop from '../../utils/userAgent/isMacDesktop';
import isWindowsDesktop from '../../utils/userAgent/isWindowsDesktop';
import UnderlineLink from '../shared/link/UnderlineLink';

import web from '../../constants/analytics/categoryActionLabel/web';

import {
  WEB_MAC_DESKTOP_HREF,
  WEB_WINDOWS_DESKTOP_HREF,
} from '../../constants/experiments/desktop';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';

const LandingDesktopDownloadLink = ({
  className,
  userAgent,
  isDesktop,
  actions,
  [WEB_MAC_DESKTOP_HREF]: macDesktopHref,
  [WEB_WINDOWS_DESKTOP_HREF]: windowsDesktopHref,
  areColorsInverted,
}) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  if (isDesktop) {
    return null;
  }

  if (isMacDesktop(userAgent)) {
    return (
      <UnderlineLink
        className={className}
        data-testid="landingHeaderMacDesktopLink"
        to={macDesktopHref}
        isNotReactRouterLink
        onClick={() =>
          actions.logWebActivity(
            web.actions.tap,
            web.labels.macDownloadHeaderNav,
          )
        }
        areColorsInverted={areColorsInverted}
      >
        {getLocalizedText(DOWNLOAD_DESKTOP_BUTTON)}
      </UnderlineLink>
    );
  }

  if (isWindowsDesktop(userAgent)) {
    return (
      <UnderlineLink
        className={className}
        data-testid="landingHeaderWindowsDesktopLink"
        to={windowsDesktopHref}
        isNotReactRouterLink
        onClick={() =>
          actions.logWebActivity(
            web.actions.tap,
            web.labels.windowsDownloadHeaderNav,
          )
        }
        areColorsInverted={areColorsInverted}
      >
        {getLocalizedText(DOWNLOAD_DESKTOP_BUTTON)}
      </UnderlineLink>
    );
  }

  return null;
};

LandingDesktopDownloadLink.propTypes = {
  className: PropTypes.string.isRequired,
  userAgent: PropTypes.string.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  [WEB_MAC_DESKTOP_HREF]: PropTypes.string,
  [WEB_WINDOWS_DESKTOP_HREF]: PropTypes.string,
  areColorsInverted: PropTypes.bool,
};

export const mapStateToProps = ({ app }) => ({
  userAgent: app.userAgent,
  isDesktop: app.isDesktop,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        logWebActivity,
      },
      dispatch,
    ),
  };
}

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  connectWithExperiments([WEB_MAC_DESKTOP_HREF, WEB_WINDOWS_DESKTOP_HREF]),
)(LandingDesktopDownloadLink);
