import interpolateComponents from 'interpolate-components';
import {
  LINE_BREAK_INSTRUCTION,
  LINE_BREAK_INSTRUCTION_REGEX,
  LINE_BREAK_INTERPOLATE,
} from '../../../constants/localizations/shared';

function createStringTemplate(stringWithLinebreakInstructions) {
  return interpolateComponents({
    mixedString: stringWithLinebreakInstructions,
    components: {
      linebreak: <br />,
    },
  });
}

export default function breakStringIntoNewLines(stringToBreak) {
  if (stringToBreak?.includes(LINE_BREAK_INTERPOLATE)) {
    return createStringTemplate(stringToBreak);
  }

  if (stringToBreak?.includes(LINE_BREAK_INSTRUCTION)) {
    const stringWithLineBreakInstructions = stringToBreak.replace(
      LINE_BREAK_INSTRUCTION_REGEX,
      LINE_BREAK_INTERPOLATE,
    );

    return createStringTemplate(stringWithLineBreakInstructions);
  }

  return stringToBreak;
}
