import PropTypes from 'prop-types';
import { Component } from 'react';
import { SIGN_UP } from 'src/common/constants/localizations/auth';
import Text from '../../Text';
import UnderlineLink from '../../shared/link/UnderlineLink';
import authLinksCss from './auth-links.module.scss';

class SignInLink extends Component {
  static propTypes = {
    authActions: PropTypes.shape({
      needsSignUpDialog: PropTypes.func.isRequired,
    }),
    areColorsInverted: PropTypes.bool,
  };

  handleClick = () => {
    const { authActions } = this.props;

    authActions.needsSignUpDialog();
  };

  render() {
    return (
      <UnderlineLink
        /* biome-ignore lint/a11y/useSemanticElements: invalid lint error for `role` on React Component */
        role="button"
        tabIndex="0"
        id="signUpLink"
        className={authLinksCss.authLink}
        onClick={this.handleClick}
        onKeyPress={this.handleClick}
        areColorsInverted={this.props.areColorsInverted}
      >
        <Text name={SIGN_UP} />
      </UnderlineLink>
    );
  }
}

export default SignInLink;
