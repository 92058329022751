import {
  Content,
  Item,
  Separator,
  Sub,
  SubContent,
  SubTrigger,
} from '@radix-ui/react-dropdown-menu';
import classNames from 'clsx';
import propTypes, { element } from 'prop-types';
import css from './menu.module.scss';

/**
 * MenuItem component renders a menu list item
 * @param {object} props - Component props
 * @param {object} props.item - The item to be displayed
 */
const MenuItem = ({ item }) => {
  const { title, onClick, itemTextClassName, ...otherProps } = item;
  return (
    <Item className={css.item} data-testid="menuItem">
      {onClick ? (
        <button
          type="button"
          className={classNames(
            itemTextClassName,
            css.itemContent,
            css.buttonItem,
          )}
          onClick={onClick}
          data-testid="menuItemButton"
          {...otherProps}
        >
          {title}
        </button>
      ) : (
        <span
          className={classNames(itemTextClassName, css.itemContent)}
          data-testid="menuItemElement"
          {...otherProps}
        >
          {title}
        </span>
      )}
    </Item>
  );
};

MenuItem.propTypes = {
  item: propTypes.object,
};

/**
 * SubMenu component renders a sub menu list of items
 * @param {object} props - Component props
 * @param {object} props.children - The class to be applied
 * @param {string} props.className - The class to be applied
 * @param {object} props.triggerItem - The menu item which is submenu parent
 */
const SubMenu = ({ children, triggerItem, className }) => {
  const { title, items, itemTextClassName, ...otherProps } = triggerItem;

  return (
    <Sub>
      <SubTrigger
        className={classNames(css.item, css.subMenuTrigger)}
        data-testid="subMenuTrigger"
      >
        <span
          className={classNames(
            itemTextClassName,
            css.itemContent,
            css.subMenuTriggerElement,
          )}
          data-testid="subMenuTriggerElement"
          {...otherProps}
        >
          {title}
          <i className={css.chevronRight} />
        </span>
      </SubTrigger>
      <SubContent
        className={classNames(css.menu, className)}
        data-testid="subMenu"
      >
        {children}
      </SubContent>
    </Sub>
  );
};

SubMenu.propTypes = {
  children: propTypes.arrayOf(element),
  className: propTypes.string,
  triggerItem: propTypes.object,
};

/**
 * Menu component Renders a list of items
 * @param {object} props - Component props
 * @param {string} props.className - The class to be applied
 * @param {string} props.subMenuClassName - The class to be applied to sub menu
 * @param {array} props.items - The items to be displayed
 * @param {'start' | 'center' | 'end'} props.align - Menu alignment
 */
const Menu = ({ className, subMenuClassName, items, align }) => {
  const renderMenuItem = (item, index) => {
    const { title, items: subItems } = item;

    if (!title) {
      return (
        <Separator
          key={index}
          className={css.divider}
          data-testid="menuSeparator"
        />
      );
    }
    return subItems ? (
      <SubMenu className={subMenuClassName} triggerItem={item}>
        {subItems.map((subItem, subIndex) => renderMenuItem(subItem, subIndex))}
      </SubMenu>
    ) : (
      <MenuItem key={index} item={item} />
    );
  };

  return (
    <Content
      side="bottom"
      align={align}
      className={classNames(className, [css.menu])}
      data-testid="dropDownEditMenu"
    >
      {items.map((item, index) => renderMenuItem(item, index))}
    </Content>
  );
};

Menu.propTypes = {
  className: propTypes.string,
  subMenuClassName: propTypes.string,
  align: propTypes.oneOf(['center', 'end', 'start']),
  items: propTypes.array,
};

export default Menu;
