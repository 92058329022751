import classNames from 'clsx';
import interpolateComponents from 'interpolate-components';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component, useContext } from 'react';
import {
  LANDING_SPLASH_HEADER_CTA,
  LANDING_SPLASH_HEADER_LOGO,
  LANDING_SPLASH_HEADER_SUBTILE,
  LANDING_SPLASH_HEADER_TITLE,
} from 'src/common/constants/experiments/config';
import {
  COMMERCIAL_FREE_MUSIC_ENABLED,
  LANDING_PREMIUM_OFFERING_TABLE_PRICE_TEXT,
} from 'src/common/constants/experiments/upsell';
import {
  LANDING_LISTEN_NOW,
  LANDING_PREMIUM_BULLET1,
  LANDING_PREMIUM_BULLET1POINT5,
  LANDING_PREMIUM_BULLET2,
  LANDING_PREMIUM_BULLET3,
  LANDING_PREMIUM_BULLET4,
  LANDING_PREMIUM_BULLET5,
  LANDING_PREMIUM_BULLET6,
  LANDING_PREMIUM_PRICING_LINE1,
  LANDING_PREMIUM_PRICING_LINE1_YEARLY,
  LANDING_PREMIUM_PRICING_LINE2,
  LANDING_UPSELL,
  LANDING_UPSELL_NEW_AUDIOBOOKS,
  LANDING_UPSELL_NEW_CTA,
  LANDING_UPSELL_NEW_MUSIC,
  LANDING_UPSELL_NEW_NEWS,
  LANDING_UPSELL_NEW_RADIO,
  LANDING_UPSELL_NEW_SPORTS,
  LANDING_UPSELL_NEW_TITLE,
} from '../../constants/localizations/landing';
import { FREE, PREMIUM } from '../../constants/localizations/shared';
import { UPSELL_START_FREE_TRIAL_BTN } from '../../constants/localizations/upsell';
import { HOME_BROWSE_PATH } from '../../constants/paths';
import connectWithExperiments from '../../decorators/connectWithExperiments';
import useExperiment from '../../hooks/useExperiment';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import cssVariables from '../../styles/variables';
import formatCents from '../../utils/subscription/formatCents';
import breakStringIntoNewLines from '../../utils/upsell/textHelpers/breakStringIntoNewLines';
import OutlinedPillButtonLink from '../shared/button/OutlinedPillButtonLink';
import PillButtonLink from '../shared/button/PillButtonLink';
import Checkmark from '../shared/svgIcons/LegacyCheckmark';
import css from './landing.module.scss';

// exported for testing
export const svgProps = {
  width: '13px',
  height: '10px',
  viewBox: '0 0 13 11',
  fill: cssVariables['--premium-gold'],
};

// ----------------- LandingStartListeningButton ----------------- //
export const LandingStartListeningButton = ({
  className,
  onClick,
  id,
  backgroundColor,
  textColor,
}) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  return (
    <PillButtonLink
      id={id}
      to={HOME_BROWSE_PATH}
      className={classNames(css.button, css.startListeningButton, className)}
      label={getLocalizedText(LANDING_LISTEN_NOW)}
      onClick={onClick}
      backgroundColor={backgroundColor}
      textColor={textColor}
    />
  );
};

LandingStartListeningButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
};

LandingStartListeningButton.defaultProps = {
  onClick: () => {},
};

// ----------------- LandingPageText ----------------- //
export const LandingPageText = ({ text }) => (
  <h1 className={css.textSection}>{text}</h1>
);
LandingPageText.propTypes = {
  text: PropTypes.string.isRequired,
};

// ----------------- LandingUpsell ----------------- //
export const LandingUpsell = ({ linkTo, onClick }) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  return (
    <div className={css.upsellContainer}>
      <p className={css.upsellText}>{getLocalizedText(LANDING_UPSELL)}</p>
      <OutlinedPillButtonLink
        dataTestId="homeStartTrialLink"
        to={linkTo}
        id="homeUpsellButton"
        className={classNames(css.button, css.upsellButtonLink)}
        label={getLocalizedText(UPSELL_START_FREE_TRIAL_BTN)}
        onClick={onClick}
      />
    </div>
  );
};

LandingUpsell.propTypes = {
  onClick: PropTypes.func.isRequired,
  linkTo: PropTypes.object.isRequired,
};

// ----------------- NewLandingUpsell ----------------- //
export const NewLandingUpsell = ({ linkTo, onClick }) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  return (
    <div className={css.newUpsellContainer}>
      <div className={css.newUpsellTextContainer}>
        <div className={css.newUpsellTitle}>
          {getLocalizedText(LANDING_UPSELL_NEW_TITLE)}
        </div>
        <table className={css.newUpsellTable}>
          <tbody>
            <tr>
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td className={css.tableText}>
                {getLocalizedText(LANDING_UPSELL_NEW_NEWS)}
              </td>
            </tr>
            <tr>
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td className={css.tableText}>
                {getLocalizedText(LANDING_UPSELL_NEW_SPORTS)}
              </td>
            </tr>
            <tr>
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td className={css.tableText}>
                {getLocalizedText(LANDING_UPSELL_NEW_AUDIOBOOKS)}
              </td>
            </tr>
            <tr>
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td className={css.tableText}>
                {getLocalizedText(LANDING_UPSELL_NEW_MUSIC)}
              </td>
            </tr>
            <tr>
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td className={css.tableText}>
                {getLocalizedText(LANDING_UPSELL_NEW_RADIO)}
              </td>
            </tr>
          </tbody>
        </table>
        <OutlinedPillButtonLink
          dataTestId="homeStartTrialLink"
          to={linkTo}
          id="homeUpsellButton"
          className={classNames(css.button, css.newUpsellButtonLink)}
          label={getLocalizedText(LANDING_UPSELL_NEW_CTA)}
          onClick={onClick}
        />
      </div>
      <div className={css.newUpsellLogosContainer} />
    </div>
  );
};

NewLandingUpsell.propTypes = {
  onClick: PropTypes.func.isRequired,
  linkTo: PropTypes.object.isRequired,
};

// ----------------- SplashHeader ----------------- //
export const SplashHeader = ({ linkTo, onClick }) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const ctaKey = useExperiment(LANDING_SPLASH_HEADER_CTA);
  const titleKey = useExperiment(LANDING_SPLASH_HEADER_TITLE);
  const title = titleKey && breakStringIntoNewLines(getLocalizedText(titleKey));
  const subtitleKey = useExperiment(LANDING_SPLASH_HEADER_SUBTILE);
  const subtitle =
    subtitleKey && breakStringIntoNewLines(getLocalizedText(subtitleKey));
  const logo = useExperiment(LANDING_SPLASH_HEADER_LOGO);
  return (
    <div
      data-testid="spashHeaderContainer"
      className={css.splashHeaderTextContainer}
    >
      {logo && (
        <div className={css.splashHeaderLogo}>
          <img data-testid="spashHeaderLogo" src={logo} alt="logo" />
        </div>
      )}
      {titleKey && (
        <div data-testid="spashHeaderTitle" className={css.splashHeaderTitle}>
          {title}
        </div>
      )}
      {subtitleKey && (
        <div
          data-testid="spashHeaderSubtitle"
          className={css.splashHeaderSubtitle}
        >
          {subtitle}
        </div>
      )}
      <OutlinedPillButtonLink
        dataTestId="spashHeaderLink"
        to={linkTo}
        id="spashHeaderLink"
        className={classNames(css.button, css.splashHeaderButtonLink)}
        label={getLocalizedText(ctaKey || LANDING_UPSELL_NEW_CTA)}
        onClick={onClick}
      />
    </div>
  );
};

SplashHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  linkTo: PropTypes.object.isRequired,
};

// ----------------- Divider ----------------- //
export const Divider = ({ className }) => (
  <div className={classNames(css.divider, className)} />
);
Divider.propTypes = {
  className: PropTypes.string,
};

// ----------------- SectionHeader ----------------- //
export const SectionHeader = ({
  headerLine1,
  headerLine2,
  headerLine1Color = cssVariables['--secondary-color-5'],
  headerLine2Color = cssVariables['--secondary-color-5'],
  className,
}) => (
  <div className={className}>
    <div style={{ color: headerLine1Color }} className={css.sectionHeader}>
      {headerLine1}
    </div>
    <div style={{ color: headerLine2Color }} className={css.sectionHeader}>
      {headerLine2}
    </div>
  </div>
);
SectionHeader.propTypes = {
  className: PropTypes.string,
  headerLine1: PropTypes.string.isRequired,
  headerLine2: PropTypes.string.isRequired,
  headerLine1Color: PropTypes.string,
  headerLine2Color: PropTypes.string,
};

// ----------------- SectionText ----------------- //
export const SectionText = ({ text, className }) => (
  <p className={classNames(css.sectionText, className)}>{text}</p>
);
SectionText.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

// ----------------- CheckIcon ----------------- //
export const CheckIcon = () => <div className={css.checkIcon} />;

// ----------------- PremiumOfferingTableComponent ----------------- //
export class PremiumOfferingTableComponent extends Component {
  static propTypes = {
    [COMMERCIAL_FREE_MUSIC_ENABLED]: PropTypes.bool,
    isFreeOffering: PropTypes.bool,
    children: PropTypes.element,
    products: PropTypes.object.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  getFreeOfferingTable() {
    const { getLocalizedText } = this.context;
    const {
      [COMMERCIAL_FREE_MUSIC_ENABLED]: commercialFreeMusicEnabled,
      children,
      products,
    } = this.props;

    return (
      <div
        data-testid="premiumOfferingTableContainer"
        className={css.tableContainer}
      >
        <p className={css.upsellHeader} data-testid="tableHeader">
          {getLocalizedText(FREE)}
        </p>
        <p data-testid="premiumPricingLn1">
          {interpolateComponents({
            mixedString: getLocalizedText(LANDING_PREMIUM_PRICING_LINE1),
            components: {
              price: <span>{formatCents(0, products.currencyCode)}</span>,
            },
          })}
        </p>
        <table className={classNames(css.table, css.tableLeft)}>
          <tbody>
            <tr data-testid="tableRow1">
              <td>
                <div className={css.checkPlaceholder} />
              </td>
              <td
                className={classNames(
                  css.productDescription,
                  css.productDescriptionDisabled,
                )}
              >
                {getLocalizedText(LANDING_PREMIUM_BULLET1)}
              </td>
            </tr>
            <tr data-testid="tableRow1point5">
              <td>
                <div className={css.checkPlaceholder} />
              </td>
              <td
                className={classNames(
                  css.productDescription,
                  css.productDescriptionDisabled,
                )}
              >
                {getLocalizedText(LANDING_PREMIUM_BULLET1POINT5)}
              </td>
            </tr>
            <tr data-testid="tableRow2">
              <td>
                <div className={css.checkPlaceholder} />
              </td>
              <td
                className={classNames(
                  css.productDescription,
                  css.productDescriptionDisabled,
                )}
              >
                {getLocalizedText(LANDING_PREMIUM_BULLET2)}
              </td>
            </tr>
            <tr data-testid="tableRow2point5">
              <td>
                <div className={css.checkPlaceholder} />
              </td>
              <td
                className={classNames(
                  css.productDescription,
                  css.productDescriptionDisabled,
                )}
              >
                {getLocalizedText(LANDING_UPSELL_NEW_AUDIOBOOKS)}
              </td>
            </tr>
            {commercialFreeMusicEnabled && (
              <tr data-testid="tableRow3">
                <td>
                  <div className={css.checkPlaceholder} />
                </td>
                <td
                  className={classNames(
                    css.productDescription,
                    css.productDescriptionDisabled,
                  )}
                >
                  {getLocalizedText(LANDING_PREMIUM_BULLET3)}
                </td>
              </tr>
            )}
            <tr data-testid="tableRow4">
              <td>
                <div className={css.checkPlaceholder} />
              </td>
              <td
                className={classNames(
                  css.productDescription,
                  css.productDescriptionDisabled,
                )}
              >
                {getLocalizedText(LANDING_PREMIUM_BULLET4)}
              </td>
            </tr>
            <tr data-testid="tableRow5">
              <td>
                <CheckIcon />
              </td>
              <td className={css.productDescription}>
                {getLocalizedText(LANDING_PREMIUM_BULLET5)}
              </td>
            </tr>
            <tr data-testid="tableRow6">
              <td>
                <CheckIcon />
              </td>
              <td className={css.productDescription}>
                {getLocalizedText(LANDING_PREMIUM_BULLET6)}
              </td>
            </tr>
          </tbody>
        </table>
        {children}
      </div>
    );
  }

  getPriceHtml(price, isMonthly) {
    const { getLocalizedText } = this.context;
    const {
      [LANDING_PREMIUM_OFFERING_TABLE_PRICE_TEXT]: premiumTablePriceKey,
    } = this.props;

    const pricingLine1 = isMonthly
      ? LANDING_PREMIUM_PRICING_LINE1
      : LANDING_PREMIUM_PRICING_LINE1_YEARLY;

    return (
      <p
        data-testid="premiumPricingLn1"
        className={classNames({
          [css.premiumPricingLine]: !price,
        })}
      >
        {interpolateComponents({
          mixedString: getLocalizedText(premiumTablePriceKey || pricingLine1),
          components: {
            price: <span>{price}</span>,
            monthlyPrice: <b>{price}</b>,
            span: <span />,
          },
        })}
      </p>
    );
  }

  getSecondLinePriceHtml() {
    const { getLocalizedText } = this.context;
    const { products } = this.props;

    const yearlyPrice = get(products, 'yearly.price');

    return (
      <p
        data-testid="premiumPricingLn2"
        className={classNames({ [css.premiumPricingLine]: !yearlyPrice })}
      >
        {interpolateComponents({
          mixedString: getLocalizedText(LANDING_PREMIUM_PRICING_LINE2),
          components: {
            price: <em>{yearlyPrice}</em>,
          },
        })}
      </p>
    );
  }

  getPremiumOfferingTable() {
    const { getLocalizedText } = this.context;
    const {
      [COMMERCIAL_FREE_MUSIC_ENABLED]: commercialFreeMusicEnabled,
      children,
      products,
    } = this.props;
    const yearlyPrice = get(products, 'yearly.price');
    const monthlyPrice = get(products, 'monthly.price');
    const shouldRenderBothLines = monthlyPrice && yearlyPrice;

    return (
      <div
        data-testid="premiumOfferingTableContainer"
        className={classNames(css.tableContainer, css.tableContainerRight)}
      >
        <p className={css.upsellHeader} data-testid="tableHeader">
          {getLocalizedText(PREMIUM)}
        </p>
        {shouldRenderBothLines && (
          <>
            {this.getPriceHtml(monthlyPrice, true)}
            {this.getSecondLinePriceHtml()}
          </>
        )}
        {!shouldRenderBothLines &&
          this.getPriceHtml(monthlyPrice || yearlyPrice, !!monthlyPrice)}
        <table className={css.table}>
          <tbody>
            <tr data-testid="tableRow1">
              <td>
                <CheckIcon />
              </td>
              <td className={css.productDescription}>
                {getLocalizedText(LANDING_PREMIUM_BULLET1)}
              </td>
            </tr>
            <tr data-testid="tableRow1point5">
              <td>
                <CheckIcon />
              </td>
              <td className={css.productDescription}>
                {getLocalizedText(LANDING_PREMIUM_BULLET1POINT5)}
              </td>
            </tr>
            <tr data-testid="tableRow2">
              <td>
                <CheckIcon />
              </td>
              <td className={css.productDescription}>
                {getLocalizedText(LANDING_PREMIUM_BULLET2)}
              </td>
            </tr>
            <tr data-testid="tableRow2point5">
              <td>
                <CheckIcon />
              </td>
              <td className={css.productDescription}>
                {getLocalizedText(LANDING_UPSELL_NEW_AUDIOBOOKS)}
              </td>
            </tr>
            {commercialFreeMusicEnabled && (
              <tr data-testid="tableRow3">
                <td>
                  <CheckIcon />
                </td>
                <td className={css.productDescription}>
                  {getLocalizedText(LANDING_PREMIUM_BULLET3)}
                </td>
              </tr>
            )}
            <tr data-testid="tableRow4">
              <td>
                <CheckIcon />
              </td>
              <td className={css.productDescription}>
                {getLocalizedText(LANDING_PREMIUM_BULLET4)}
              </td>
            </tr>
            <tr data-testid="tableRow5">
              <td>
                <CheckIcon />
              </td>
              <td className={css.productDescription}>
                {getLocalizedText(LANDING_PREMIUM_BULLET5)}
              </td>
            </tr>
            <tr data-testid="tableRow6">
              <td>
                <CheckIcon />
              </td>
              <td className={css.productDescription}>
                {getLocalizedText(LANDING_PREMIUM_BULLET6)}
              </td>
            </tr>
          </tbody>
        </table>
        {children}
      </div>
    );
  }

  render() {
    return this.props.isFreeOffering
      ? this.getFreeOfferingTable()
      : this.getPremiumOfferingTable();
  }
}

export const PremiumOfferingTable = connectWithExperiments([
  COMMERCIAL_FREE_MUSIC_ENABLED,
  LANDING_PREMIUM_OFFERING_TABLE_PRICE_TEXT,
])(PremiumOfferingTableComponent);
