import classNames from 'clsx';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { ACCOUNT_PATH } from 'src/common/constants/paths.js';

import getUserDataUrl from 'src/common/utils/paths/getUserDataUrl';
import {
  LIBRARY,
  LOGOUT,
  SETTINGS,
} from '../../constants/localizations/header';
import assetUrl from '../../utils/assetUrl';
import FavoritesLoader from '../favorites/FavoritesLoader';
import DropdownMenu from '../shared/dropdownMenu/DropdownMenu';

import { MY_ACCOUNT } from '../../constants/localizations/shared';
import withLocationAndLocalization from '../../decorators/withLocationAndLocalization';
import css from './profile-menu.module.scss';

const downCaretLoc = 'assets/img/shared/caret-down-v2.svg';
const defaultDownCaretFill = 'white';

function ProfileMenu({
  userDetails,
  textOnly,
  downCaretColor,
  getLocalizedText,
  history,
  authActions,
  className,
}) {
  const handleLogoutClick = () => {
    authActions.logout();
  };

  const handleFavoritesClick = () => {
    history.push(getUserDataUrl(userDetails));
  };

  const handleSettingsClick = () => {
    history.push(ACCOUNT_PATH);
  };

  const downCaret = (
    <ReactSVG
      src={assetUrl(downCaretLoc)}
      beforeInjection={(svg) => {
        // note: IE11 does not support Element.classlist for SVG elements
        svg.setAttribute('class', css.chevron);
        svg.style.fill = downCaretColor || defaultDownCaretFill; // eslint-disable-line no-param-reassign
      }}
    />
  );

  const menuButton = (
    <div
      className={classNames(css.menuSelector, className)}
      data-testid="myAccount"
    >
      {textOnly ? (
        <span>{getLocalizedText(MY_ACCOUNT)}</span>
      ) : (
        <div
          className={css.avatar}
          style={{
            backgroundImage: `url(${userDetails && userDetails.image})`,
          }}
        />
      )}
      {downCaret}
    </div>
  );

  const menuConfig = {
    button: {
      element: menuButton,
    },
    items: [
      {
        id: 'favoritesMenuItem',
        title: getLocalizedText(LIBRARY),
        onClick: handleFavoritesClick,
        onMouseOver: () => FavoritesLoader.preload(),
      },
      {
        id: 'settingsMenuItem',
        title: getLocalizedText(SETTINGS),
        onClick: handleSettingsClick,
      },
      {},
      {
        id: 'logoutMenuItem',
        title: getLocalizedText(LOGOUT),
        onClick: handleLogoutClick,
      },
    ],
    align: 'end',
  };

  return <DropdownMenu {...menuConfig} />;
}

ProfileMenu.propTypes = {
  history: PropTypes.object.isRequired,
  userDetails: PropTypes.object,
  authActions: PropTypes.shape({
    logout: PropTypes.func.isRequired,
  }),
  downCaretColor: PropTypes.string,
  textOnly: PropTypes.bool,
  getLocalizedText: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default flow(withRouter, withLocationAndLocalization)(ProfileMenu);
