import { Root, Trigger } from '@radix-ui/react-dropdown-menu';
import classNames from 'clsx';
import propTypes from 'prop-types';
import Menu from './Menu';
import css from './dropdown-menu.module.scss';

/**
 * DropdownMenu menu component Renders a list of items in a dropdown
 * @param {object} props - Component props
 * @param {object} props.button - The attributes to be applied to the button
 * @param {array} props.items - The items to be displayed
 * @param {string} props.className - The class to be applied
 * @param {string} props.subMenuClassName - The class to be applied to sub menu
 * @param {'start' | 'center' | 'end'} props.align - Menu alignment (by deafult set to "start")
 */
const DropdownMenu = ({
  button,
  items,
  className,
  subMenuClassName = '',
  align = 'start',
}) => {
  return (
    <Root modal={false} data-testid="dropdownMenu">
      <Trigger
        className={classNames(button.parentClassName, css.button)}
        data-testid="dropdownMenuTrigger"
      >
        {button.element}
      </Trigger>
      <Menu
        items={items}
        className={classNames(css.menu, className)}
        subMenuClassName={subMenuClassName}
        align={align}
      />
    </Root>
  );
};

DropdownMenu.propTypes = {
  button: propTypes.shape({
    element: propTypes.element,
    parentClassName: propTypes.string,
  }),
  items: propTypes.array,
  className: propTypes.string,
  subMenuClassName: propTypes.string,
  align: propTypes.oneOf(['center', 'end', 'start']),
};

export default DropdownMenu;
