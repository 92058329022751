import { useContext } from 'react';
import { LocationAndLocalizationContext } from '../providers/LocationAndLocalizationProvider';

export default function withLocationAndLocalization(BaseComponent) {
  const WrappedComponent = (props) => {
    const contextValues = useContext(LocationAndLocalizationContext);

    return <BaseComponent {...props} {...contextValues} />;
  };

  return WrappedComponent;
}
