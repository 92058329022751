import PropTypes from 'prop-types';

import SignInLink from './SignInLink';
import SignUpLink from './SignUpLink';
import authLinksCss from './auth-links.module.scss';

const AuthLinksContainer = ({
  authActions,
  hideDivider,
  areColorsInverted,
}) => (
  <div className={authLinksCss.container}>
    <SignInLink
      authActions={authActions}
      areColorsInverted={areColorsInverted}
    />
    {hideDivider ? (
      <i className={authLinksCss.dividerSpace} />
    ) : (
      <i className={authLinksCss.divider}>•</i>
    )}
    <SignUpLink
      authActions={authActions}
      areColorsInverted={areColorsInverted}
    />
  </div>
);

AuthLinksContainer.propTypes = {
  authActions: PropTypes.shape({
    needsSignInDialog: PropTypes.func.isRequired,
    needsSignUpDialog: PropTypes.func.isRequired,
  }),
  hideDivider: PropTypes.bool,
  areColorsInverted: PropTypes.bool,
};

export default AuthLinksContainer;
