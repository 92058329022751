import classNames from 'clsx';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AUDIOBOOKS_REGULAR_CASE,
  MUSIC_REGULAR_CASE,
  NEWS_AND_TALK_REGULAR_CASE,
  PODCASTS_REGULAR_CASE,
  PREMIUM,
  SPORTS_REGULAR_CASE,
} from 'src/common/constants/localizations/shared';
import subscribe, {
  labels,
} from '../../constants/analytics/categoryActionLabel/subscribe';
import { PREMIUM_PATH } from '../../constants/cmsLinks';
import { LANDING_SPLASH_HEADER_ENABLED } from '../../constants/experiments/config';
import { BYPASS_UPSELL_DIALOG_ENABLED } from '../../constants/experiments/dialog';
import { LANDING_LISTEN_NOW } from '../../constants/localizations/landing';
import {
  AUDIOBOOKS_BROWSE_PATH,
  HOME_BROWSE_PATH,
  MUSIC_BROWSE_PATH,
  NEWS_BROWSE_PATH,
  PODCASTS_BROWSE_PATH,
  PREMIUM_BROWSE_PATH,
  SPORTS_BROWSE_PATH,
} from '../../constants/paths';
import connectWithAuth from '../../decorators/auth/connectWithAuth';
import withScrollPosition from '../../decorators/withScrollPosition';
import usePrevious from '../../hooks/usePrevious';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectExperiment } from '../../selectors/config';
import cssVariables from '../../styles/variables';
import { isLarge } from '../../utils/breakpoints';
import getSubscribeEventLabel from '../../utils/subscription/getSubscribeEventLabel';
import ProfileMenu from '../header/ProfileMenu';
import AuthLinksContainer from '../header/links/AuthLinksContainer';
import UnderlineLink from '../shared/link/UnderlineLink';
import TuneInMarquee from '../shared/svgIcons/TuneInMarquee';
import { LandingStartListeningButton } from './LandingComponents';
import LandingDesktopDownloadLink from './LandingDesktopDownloadLink';
import css from './wide-header.module.scss';

const homePageTopLinks = [
  { name: AUDIOBOOKS_REGULAR_CASE, href: AUDIOBOOKS_BROWSE_PATH },
  { name: SPORTS_REGULAR_CASE, href: SPORTS_BROWSE_PATH },
  { name: MUSIC_REGULAR_CASE, href: MUSIC_BROWSE_PATH },
  { name: NEWS_AND_TALK_REGULAR_CASE, href: NEWS_BROWSE_PATH },
  { name: PODCASTS_REGULAR_CASE, href: PODCASTS_BROWSE_PATH },
];

function WideHeader({
  authActions,
  userDetails,
  isAuthenticated,
  positionOne,
  positionTwo,
  positionThree,
  subscriptionEnabled,
  isUserSubscribed,
  logSubscribeActivity,
  areColorsInverted,
  hideAuthLinks,
  breakpoint,
}) {
  const { location, getLocalizedText } = useContext(
    LocationAndLocalizationContext,
  );
  const landingSplashHeaderEnabled = useSelector((state) =>
    selectExperiment(state, LANDING_SPLASH_HEADER_ENABLED),
  );
  const bypassUpsellDialogEnabled = useSelector((state) =>
    selectExperiment(state, BYPASS_UPSELL_DIALOG_ENABLED),
  );
  const isLargeBreakpoint = isLarge(breakpoint);
  const prevIsLargeBreakpoint = usePrevious(isLargeBreakpoint);
  const [didLogLandingHeaderUpsell, setDidLogLandingHeaderUpsell] =
    useState(false);
  const currentTopLinks = [...homePageTopLinks];
  const shouldLogLandingHeaderUpsell =
    !prevIsLargeBreakpoint &&
    isLargeBreakpoint &&
    !didLogLandingHeaderUpsell &&
    subscriptionEnabled &&
    !isUserSubscribed;

  if (shouldLogLandingHeaderUpsell) {
    const eventLabel = getSubscribeEventLabel(
      labels.landingHeaderUpsell,
      location,
    );
    logSubscribeActivity(subscribe.actions.show, eventLabel);
    setDidLogLandingHeaderUpsell(true);
  }

  if (subscriptionEnabled && !isUserSubscribed) {
    const urlPath = bypassUpsellDialogEnabled
      ? `${PREMIUM_BROWSE_PATH}?source=${subscribe.labels.landingHeaderUpsell}`
      : `${PREMIUM_PATH}?source=${subscribe.labels.landingHeaderUpsell}`;

    currentTopLinks.push({
      name: PREMIUM,
      href: urlPath,
      isExternalLink: !bypassUpsellDialogEnabled,
    });
  }

  return (
    <div
      className={classNames(css.wideHeader, {
        [css.positionOne]: positionOne,
        [css.positionTwo]: positionTwo,
        [css.positionThree]: positionThree,
      })}
      data-testid={positionThree ? 'scrolledWideHeader' : 'wideHeader'}
    >
      <div
        className={classNames(css.logologo, {
          [css.landingSplashHeaderBackground]: landingSplashHeaderEnabled,
        })}
      >
        <TuneInMarquee
          fill={
            areColorsInverted
              ? cssVariables['--white']
              : cssVariables['--t-sharp']
          }
        />
      </div>
      <UnderlineLink
        data-testid="scrolledHeaderListenNowLink"
        className={css.stickyListenNow}
        to={HOME_BROWSE_PATH}
        areColorsInverted={areColorsInverted}
      >
        {getLocalizedText(LANDING_LISTEN_NOW)}
      </UnderlineLink>
      <div className={css.navDivider} />
      <div className={css.mainNavLinks}>
        <LandingStartListeningButton
          id="headerListenButtonLink"
          className={classNames(css.headerButton, {
            [css.landingSplashHeaderPadding]: landingSplashHeaderEnabled,
          })}
          backgroundColor={areColorsInverted ? cssVariables['--white'] : ''}
          textColor={
            areColorsInverted ? cssVariables['--secondary-color-5'] : ''
          }
        />
        {currentTopLinks.map((link, i) => (
          <UnderlineLink
            className={css.mainNavLink}
            key={link.name}
            data-testid={`topSectionLink-${i}`}
            to={link.href}
            isNotReactRouterLink={link.isExternalLink}
            areColorsInverted={areColorsInverted}
          >
            {getLocalizedText(link.name)}
          </UnderlineLink>
        ))}
        <LandingDesktopDownloadLink
          className={css.mainNavLink}
          areColorsInverted={areColorsInverted}
        />
      </div>
      <div
        className={classNames(css.authLinks, {
          [css.invertedColors]: areColorsInverted,
        })}
      >
        {isAuthenticated && (
          <ProfileMenu
            className={css.myAccount}
            userDetails={userDetails}
            authActions={authActions}
            downCaretColor={
              positionTwo || areColorsInverted
                ? cssVariables['--white']
                : cssVariables['--secondary-color-5']
            }
            textOnly
          />
        )}
        {!isAuthenticated && !hideAuthLinks && (
          <AuthLinksContainer
            authActions={authActions}
            areColorsInverted={areColorsInverted}
            hideDivider
          />
        )}
      </div>
    </div>
  );
}

WideHeader.propTypes = {
  authActions: PropTypes.object,
  userDetails: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  positionOne: PropTypes.bool,
  positionTwo: PropTypes.bool,
  positionThree: PropTypes.bool,
  subscriptionEnabled: PropTypes.bool.isRequired,
  isUserSubscribed: PropTypes.bool.isRequired,
  logSubscribeActivity: PropTypes.func.isRequired,
  areColorsInverted: PropTypes.bool,
  hideAuthLinks: PropTypes.bool,
  breakpoint: PropTypes.number.isRequired,
};

export default flow(withScrollPosition(), connectWithAuth)(WideHeader);
